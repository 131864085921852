export function assert(
  condition: any,
  message?: string,
  status?: number,
): asserts condition {
  if (!condition) {
    throw new Response(message ?? undefined, {
      status: status ?? 400,
      statusText: message,
    });
  }
}
